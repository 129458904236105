<div class="container">
  <div class="buttons">
    <button type="button" class="btn btn-info mb-3" (click)="openUserModal()">CREATE USER</button>
    <button type="button" class="btn btn-info mb-3" (click)="approveUsers()" [disabled]="isLoading">APPROVE USER
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <button type="button" class="btn btn-info mb-3" (click)="deleteUsers()" [disabled]="isLoading1">DELETE USER
      <span *ngIf="isLoading1" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <!-- <button type="button" class="btn btn-info mb-3" (click)="deleteAllUsers()" [disabled]="isLoading3">DELETE ALL USERS
      <span *ngIf="isLoading3" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <button type="button" class="btn btn-info mb-3" (click)="deleteAllChats()" [disabled]="isLoading4">DELETE ALL CHATS
      <span *ngIf="isLoading4" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button> -->
    <button type="button" class="btn btn-info mb-3" (click)="openEmailModal()">SEND EMAIL</button>
    <button type="button" class="btn btn-info mb-3" (click)="openCustomEmailModal()">SEND CUSTOM EMAIL</button>
    <button type="button" class="btn btn-info mb-3" (click)="displayFilters()" *ngIf="!showFilters">SHOW
      FILTERS
    </button>
    <button type="button" class="btn btn-info mb-3" (click)="displayFilters()" *ngIf="showFilters">HIDE FILTERS</button>
    <button type="button" class="btn btn-info mb-3" (click)="fetchUsers()" [disabled]="fetchingUsers">REFRESH
      <span *ngIf="fetchingUsers" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
  </div>
  <div class="filters" *ngIf="showFilters">
    <div class="row">
      <div class="col-md-3" *ngFor="let filter of filterSelectObj">
        <mat-form-field class="full-width">
          <mat-label>Filter {{filter.name}}</mat-label>
          <select matNativeControl name="{{filter.columnProp}}" [(ngModel)]="filter.modelValue"
            (change)="filterChange(filter, $event)">
            <option [value]="item" *ngFor="let item of filter.options">{{item | titlecase}}</option>
          </select>
        </mat-form-field>
      </div>
    </div>
    <div class="buttons">
      <button type="button" class="btn btn-info" (click)="resetFilters()">RESET FILTERS</button>
    </div>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- SELECT COLUMN -->
      <ng-container matColumnDef="select">
        <th style="width: 60px;" mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- FULLNAME COLUMN -->
      <ng-container matColumnDef="fullname">
        <th mat-header-cell *matHeaderCellDef> Full Name </th>
        <td mat-cell *matCellDef="let user" [ngClass]="{'online': user.online, 'offline': !user.online}">
          {{user.firstname | titlecase}} {{user.lastname | titlecase}}
        </td>
      </ng-container>

      <!-- EMAIL COLUMN -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
      </ng-container>

      <!-- ROLE COLUMN -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef> Role </th>
        <td mat-cell *matCellDef="let user"> {{user.role | titlecase}} </td>
      </ng-container>

      <!-- LAST ACTIVE COLUMN -->
      <ng-container matColumnDef="last_active">
        <th mat-header-cell *matHeaderCellDef> Last Active</th>
        <td mat-cell *matCellDef="let user"> {{user.last_active | date: 'medium'}} </td>
      </ng-container>

      <!-- CREATED AT COLUMN -->
      <ng-container matColumnDef="created_At">
        <th mat-header-cell *matHeaderCellDef> Created At </th>
        <td mat-cell *matCellDef="let user"> {{user.created_on | date: 'mediumDate'}} </td>
      </ng-container>

      <!-- ACCOUNT STATUS COLUMN -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let user"> {{user.account_status | titlecase}} </td>
      </ng-container>

      <!-- VIEW USER DETAILS COLUMN -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="header-align-right"> View User </th>
        <td mat-cell *matCellDef="let user">
          <fa-icon class="icon" [icon]="viewIcon" size="1x" (click)="viewUserDetails(user)"></fa-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="d-flex justify-content-center" *ngIf="fetchingUsers">
    <mat-spinner diameter="20" style="margin-top: 10px;"></mat-spinner>
  </div>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>


  <!-- SEND EMAIL MODAL -->
  <ng-template #sendEmailModal let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Send Email</h5>
    </div>
    <div class="modal-body">
      <form [formGroup]="emailForm">
        <div class="form-group mb-3">
          <label for="subject">Subject</label>
          <input type="text" class="form-control" formControlName="subject"
            [ngClass]="{ 'is-invalid': submitted && g['subject'].errors}" autofocus>
          <div *ngIf="submitted && g['subject'].errors" class="invalid-feedback">
            <div *ngIf="g['subject']['errors']required">Subject is required</div>
          </div>
        </div>
        <div class="form-group mb-3">
          <label for="body">Body</label>
          <textarea class="form-control" formControlName="body" rows="8" placeholder="Email content..."></textarea>
          <div *ngIf="submitted && g['body'].errors" class="invalid-feedback">
            <div *ngIf="g['body']['errors']required">Body is required</div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dark" (click)="close()">CLOSE</button>
      <button type="button" class="btn btn-dark" (click)="sendEmail()" [disabled]="sendEmailLoading">SEND EMAIL
        <span *ngIf="sendEmailLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </ng-template>

  <!-- SEND CUSTOM EMAIL MODAL -->
  <ng-template #sendCustomEmailModal let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Send Custom Email</h5>
    </div>
    <div class="modal-body">
      <form [formGroup]="customEmailForm">
        <div class="form-group mb-3">
          <label for="subject">Subject</label>
          <input type="text" class="form-control" formControlName="subject"
            [ngClass]="{ 'is-invalid': submitted && h['subject'].errors}" autofocus>
          <div *ngIf="submitted && h['subject'].errors" class="invalid-feedback">
            <div *ngIf="h['subject']['errors']required">Subject is required</div>
          </div>
        </div>
        <div class="form-group mb-3">
          <label for="email">Email *</label>
          <input type="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors}">
          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
            <div *ngIf="h['email']['errors']required">Email is required</div>
            <div *ngIf="h['email']['errors']pattern">Input a valid email in lowercase</div>
          </div>
        </div>
        <div class="form-group">
          <label for="body">Body</label>
          <textarea class="form-control" formControlName="body" rows="8" placeholder="Email content..."></textarea>
          <div *ngIf="submitted && h['body'].errors" class="invalid-feedback">
            <div *ngIf="h['body']['errors']required">Body is required</div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dark" (click)="close()">CLOSE</button>
      <button type="button" class="btn btn-dark" (click)="sendCustomEmail()" [disabled]="sendCustomEmailLoading">SEND CUSTOM EMAIL
        <span *ngIf="sendCustomEmailLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </ng-template>

  <!-- CREATE USER MODAL -->
  <ng-template #createUserModal let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Create User</h5>
    </div>
    <div class="modal-body">
      <form [formGroup]="userForm">
        <div class="form-group">
          <label for="firstname">Firstname</label>
          <input type="text" class="form-control" formControlName="firstname"
            [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors}">
          <div *ngIf="submitted && f['firstname'].errors" class="invalid-feedback">
            <div *ngIf="f['firstname']['errors']required">Firstname is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="lastname">Lastname</label>
          <input type="text" class="form-control" formControlName="lastname"
            [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors}">
          <div *ngIf="submitted && f['lastname'].errors" class="invalid-feedback">
            <div *ngIf="f['lastname']['errors']required">Lastname is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" class="form-control" formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && f['email'].errors}">
          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
            <div *ngIf="f['email']['errors']required">Email is required</div>
            <div *ngIf="f['email']['errors']pattern">Input a valid email in lowercase</div>
          </div>
        </div>
        <mat-form-field class="full-width">
          <mat-label>Role</mat-label>
          <mat-select matNativeControl formControlName="role">
            <mat-option *ngFor="let role of userRoles" [value]="role">{{role | titlecase}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dark" (click)="close()">Close</button>
      <button class="btn btn-dark" (click)="createUser()" [disabled]="isLoading2">Create User
        <span *ngIf="isLoading2" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </ng-template>

  <!-- USER DETAILS MODAL -->
  <ng-template #userDetailsModal let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">{{selectedUser.firstname}} {{selectedUser.lastname}}</h5>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6">
          <p><strong>FirstName: </strong>{{selectedUser.firstname}}</p>
          <p><strong>LastName: </strong>{{selectedUser.lastname}}</p>
          <p><strong>Email: </strong>{{selectedUser.email}}</p>
          <p><strong>Role: </strong>{{selectedUser.role | titlecase}}</p>
          <p><strong>Account Status: </strong>{{selectedUser.account_status | titlecase}}</p>
          <p><strong>Last Active: </strong>{{selectedUser.last_active | date: 'medium'}}</p>
          <p><strong>Created On: </strong>{{selectedUser.created_on | date: 'mediumDate'}}</p>
          <p><strong>Updated On: </strong>{{selectedUser.updated_on | date: 'mediumDate'}}</p>
        </div>
        <div class="col-md-6" *ngIf="selectedUser.role == 'freelancer'">
          <p><strong>Bio: </strong>{{selectedUser.bio}}</p>
          <p><strong>Title: </strong>{{selectedUser.title}}</p>
          <p><strong>Rating: </strong>{{selectedUser.average_rating}}</p>
          <p><strong>Resume: </strong><a class="resume" (click)="downloadResume(selectedUser.resume, selectedUser._id)">{{selectedUser.resume}}</a></p>
          <p class="skills"><strong>Skills: </strong></p>
          <ul class="skills-list">
            <li *ngFor="let skill of selectedUser.skills">{{ skill }}</li>
          </ul>
          <p class="reviews"><strong>Reviews: </strong></p>
          <ul class="reviews-list">
            <li *ngFor="let review of selectedUser.reviews">
              <p><strong>Reviewer:</strong> {{ review.firstname }} {{ review.lastname }}</p>
              <p><strong>Comment:</strong> {{ review.comment }}</p>
              <p><strong>Rating:</strong> {{ review.rating }} / 5</p>
              <p><strong>Reviewed On:</strong> {{ review.reviewed_on | date: 'mediumDate' }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dark" (click)="close()">CLOSE</button>
    </div>
  </ng-template>
</div>