<div class="container">
    <div class="d-flex justify-content-center" *ngIf="loading">
        <app-loading></app-loading>
    </div>
    <div class="card top" *ngIf="!loading && jobID">
        <h2 class="card-title">{{job.title}}</h2>
        <p class="card-subtitle mb-2 text-body-secondary">{{job.description}}</p>
        <div class="row mb-3">
            <div class="col-md-3">
                <p class="card-text"><strong>Posted: </strong>{{job.created_on | date: 'mediumDate'}}</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Budget: </strong>{{(job.budget).toFixed(2)}} USD</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Kaziflex: </strong>{{(job.kaziflex_amount).toFixed(2)}} USD</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Freelancer: </strong>{{(job.freelancer_amount).toFixed(2)}} USD</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3">
                <p class="card-text"><strong>Applicants: </strong>{{job.applicants.length}}</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Status: </strong>{{job.status}}</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Deadline: </strong>{{job.deadline | date: 'mediumDate'}}</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3">
                <p class="card-text"><strong>Amount Paid: </strong>{{(job.amountPaid).toFixed(2)}} USD</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Balance: </strong>{{job.balance}} USD</p>
            </div>
            <div class="col-md-3">
                <p class="card-text"><strong>Percent Paid By client: </strong>{{(job.amountPaid/job.budget)*100}}%</p>
            </div>
        </div>
        <p class="card-text mb-5"><strong>Required Skills: </strong>{{job.skills}}</p>
        <h3 *ngIf="job.milestones.length > 0">Milestones</h3>
        <div *ngFor="let milestone of job.milestones" class="mb-3">
            <div class="row mb-2">
                <div class="col-md-4">
                    <p class="card-text"><strong>Deadline: </strong>{{milestone.milestoneDeadline | date: 'mediumDate'}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="card-text"><strong>Description: </strong>{{milestone.milestoneDescription }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row middle mb-3" *ngIf="freelancerSelected && jobID">
        <h3>Selected Freelancer</h3>
        <div class="col-md-4 wrapper">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <div class="avatar">
                        <div class="avatar-preview">
                            <div class="image-preview"
                                [style.backgroundImage]="'url('+ this.job.freelancer.profile_image +')'">
                            </div>
                        </div>
                    </div>
                    <h5 class="card-title">{{job.freelancer.firstname}} {{job.freelancer.lastname}}</h5>
                    <p class="card-text text-center">{{job.freelancer.title}}</p>
                    <p class="card-text text-center"><span class="badge rounded-pill text-bg-light"
                        *ngFor="let skill of job.freelancer.skills">{{skill}}</span>
                    </p>
                    <p class="card-text text-center applied">Applied On: {{job.freelancer.applied_on | date: 'mediumDate'}}</p>
                    <p class="card-text text-center applied">Approved On: {{job.freelancer.approved_on | date: 'mediumDate'}}</p>
                    <button class="btn btn-info" (click)="displayApplicantProfile(job._id, job.freelancer._id)">VIEW PROFILE</button>
                    <button class="btn btn-info" (click)="sendMessage(job.freelancer)">SEND MESSAGE</button>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <p class="text-center" *ngIf="job.submissions.length == 0"><strong>Work that has been submitted will be displayed here.</strong></p>
            <div class="submissions" *ngIf="job.submissions.length > 0">
                <h3>Submissions</h3>
                <div class="card mb-2" *ngFor="let submission of job.submissions | paginate : submissionsPagingConfig; let i = index">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-md-4">
                                <p><strong>Submitted On: </strong>{{submission.submitted_on | date: 'mediumDate'}}</p>
                            </div>
                            <div class="col-md-4">
                                <p><strong>URL: </strong><a href={{submission.link}} target="_blank">Link to Submitted Work</a></p>
                            </div>
                            <div class="col-md-4">
                                <p><strong>Comment: </strong>{{ submission.comment }}</p>
                            </div>
                        </div>
                        <div class="file" *ngIf="submission.file">
                            <button class="btn btn-info" (click)="downloadFile(submission.file)" [disabled]="downloadingFile">
                                DOWNLOAD FILE
                                <span *ngIf="downloadingFile" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onSubmissionsDataChange($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <div class="row bottom">
        <div class="applicants" *ngIf="!loading && jobID">
            <h3>Applicants</h3>
            <div class="no-applicants" *ngIf="job.applicants.length == 0">
                <p>There are no applicants yet.</p>
            </div>
            <div class="row">
                <div class="col-md wrapper mb-5" *ngFor="let applicant of job.applicants">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <div class="avatar">
                                <div class="avatar-preview">
                                    <div class="image-preview"
                                        [style.backgroundImage]="'url('+ applicant.profile_image +')'"></div>
                                </div>
                            </div>
                            <h5 class="card-title">{{applicant.firstname}} {{applicant.lastname}}</h5>
                            <p class="card-text text-center">{{applicant.title}}</p>
                            <p class="card-text text-center"><span class="badge rounded-pill text-bg-light"
                                *ngFor="let skill of applicant.skills ">{{skill}}</span>
                            </p>
                            <p class="card-text text-center applied">Applied On: {{applicant.applied_on | date: 'mediumDate'}}</p>
                            <button class="btn btn-info" (click)="displayApplicantProfile(job._id, applicant._id)">VIEW PROFILE</button>
                            <button class="btn btn-info" (click)="sendMessage(applicant)">SEND MESSAGE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
