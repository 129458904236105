import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { Job } from '../../../shared/Interfaces/Job';
import { NotificationService } from '../../../core/services/notification.service';
import { Applicant } from '../../../shared/Interfaces/Applicant';
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';

@Component({
  selector: 'app-job-details',
  templateUrl: './admin-job-details.component.html',
  styleUrl: './admin-job-details.component.scss'
})
export class AdminJobDetailsComponent implements OnInit, PagingConfig {
  /**
   * The job whose details are being displayed
   */
  job: Job;
  /**
   * Stores the ID received from the history state
   */
  jobID: string;
  /**
   * Displays spinner when waiting for the job to be fetched from the backend
   */
  loading: boolean;
  /**
   * Stores the current page of the paginator
   */
  currentPage: number = 1;
  /**
   * Stores the count of the paginator
   */
  totalItems: number = 0;
  /**
   * Stores the total items displayed per page
   */
  itemsPerPage: number = 4;
  /**
   * Indicates whether a freelancer has been selected
   */
  freelancerSelected: boolean = false;
  /**
   * Used to display spinner when downloading a file
   */
  downloadingFile: boolean;

  submissionsPagingConfig: PagingConfig = {} as PagingConfig;

  /**
   * Injects dependencies into the component
   */
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private notify: NotificationService,
  ) { }

  /**
   * Retrieves the ID of the job from the state variable
   */
  ngOnInit(): void {
    this.jobID = this.route.snapshot.url[2].path;
    this.fetchJob();
    this.submissionsPagingConfig = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: 0
    }
  }

  /**
   * Fetches the job details from an API endpoint
   */
  fetchJob(): void {
    this.loading = true;
    if (this.jobID) {
      this.api.get('/jobs/admin/' + this.jobID).subscribe({
        next: res => {
          if (res.status_code === 200) {
            this.job = res.detail;
          } else {
            this.notify.showError(res.detail);
            this.jobID = '';
          }
          this.loading = false;
        },
        error: (e) => {
          this.loading = false;
          console.error(e);
        }
      });
    } else {
      this.notify.showError('Kindly provide a job ID');
      this.loading = false;
    }
  }


  /**
   * Detects if the data in the submissions has changed
   * @param page - The page that has been selected
   */
  onSubmissionsDataChange(event: any): void{
    this.submissionsPagingConfig.currentPage = event;
    this.fetchJob();
  }

  /**
   * Redirects to the applicant profile component
   * @param jobID - The ID of the selected job
   * @param applicantID - The ID of the selected freelancer
   * Stores job details in browser state
   */
  displayApplicantProfile(jobID: string, applicantID: string): void {
    this.router.navigate([`posted-jobs/${jobID}/applicants/${applicantID}`], { state: { jobID: jobID, applicantID: applicantID } });
  }

  sendMessage(freelancer: Applicant) {
    this.router.navigate(['messages'], {
      state: { 
        _id: freelancer._id,
        firstname: freelancer.firstname,
        lastname: freelancer.lastname 
      }
    });
  }

  downloadFile(file_name: string): void {
    this.downloadingFile = true;
    this.api.getFile(`/jobs/download-file/${file_name}`).subscribe(
      blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = file_name; // Replace with your filename and extension
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.downloadingFile = false;
      }
    )
  }

}